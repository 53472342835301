import { addBreadcrumb, captureException } from '@sentry/nextjs';

export const ErrorTracking = {
  addBreadcrumb,
  track(
    err: Error | unknown,
    logDetails?: { message?: string; options?: Record<string, unknown> },
  ): void {
    if (logDetails && (logDetails.message || logDetails.options)) {
      addBreadcrumb({
        message: logDetails.message ?? undefined,
        data: logDetails.options ?? undefined,
      });
    }
    captureException(err);
  },
};

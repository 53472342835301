import { PrioritiesType } from '@nextTypes/quiz';
import {
  MultipleChoicePayload,
  ReducerState,
  RestartActionType,
} from './types';

export const initialPromoCodeState = {
  code: null,
  isApplyingCode: false,
  codeValidity: null,
  errorMessage: null,
};

export const initialState: ReducerState = {
  promoCodeState: initialPromoCodeState,
  selectedPlanId: null,
  selectedProductName: null,
  age: 0,
  birthday: undefined,
  heightUnit: 'ft',
  heightCm: 0,
  heightFT: 0,
  heightINCH: 0,
  weightUnit: 'st',
  weightKg: 0,
  weightST: 0,
  weightLBS: 0,
  hasWeightGoalInMind: undefined,
  weightLossGoalKg: 0,
  weightLossGoalSt: 0,
  weightLossGoalLbs: 0,
  gender: undefined,
  pregnancy: undefined,
  healthConditions: [],
  healthConditionsOpenText: '',
  takesMedication: undefined,
  currentMedication: '',
  hasAllergies: undefined,
  allergies: '',
  diabetesStatus: undefined,
  type2DiabetesMedication: undefined,
  openToMedication: undefined,
  addressFirstLine: '',
  addressSecondLine: '',
  addressCity: '',
  addressPostCode: '',
  addressCountry: '',
  billingAddressFirstLine: '',
  billingAddressSecondLine: '',
  billingAddressCity: '',
  billingAddressPostCode: '',
  billingAddressCountry: '',
  firstName: '',
  lastName: '',
  namePrefix: '',
  priorities: {},
  mobileNumber: '',
  gpName: '',
  gpAddress: '',
  shareInfoWithGP: undefined,
};

const isKeyOf = <T extends Record<string | number | symbol, unknown>>(
  object: T,
  key: string | number | symbol,
): key is keyof T => key in object;

export const isKeyOfReducerState = (key: string): key is keyof ReducerState =>
  key in initialState;

const clearPriorityTextAnswer = (state: ReducerState, payload: string) => {
  const updatedPriorities = state.priorities;

  if (updatedPriorities) {
    // Need to typecast the following as the payload string can be any quiz question key as our types are not specific enough here
    delete updatedPriorities[payload as PrioritiesType];
  }

  return {
    ...state,
    priorities: {
      ...state.priorities,
      ...updatedPriorities,
    },
  };
};

const setMultipleChoiceAnswer = (
  state: ReducerState,
  { question, answer }: MultipleChoicePayload,
) => {
  const questionState = state[question] || {};

  let currentValue;
  if (isKeyOf(questionState, answer)) {
    currentValue = questionState?.[answer];
  }

  return {
    ...state,
    [question]: {
      ...state[question],
      [answer]: !currentValue,
    },
  };
};

export const reducer = (
  state: ReducerState,
  action: RestartActionType,
): ReducerState => {
  switch (action.type) {
    case 'SET_PROMO_CODE':
      return {
        ...state,
        promoCodeState: {
          ...state.promoCodeState,
          ...action.payload,
        },
      };
    case 'CLEAR_PROMO_CODE':
      return {
        ...state,
        promoCodeState: initialPromoCodeState,
      };
    case 'SET_SELECTED_PLAN_ID':
      return {
        ...state,
        selectedPlanId: action.payload,
      };
    case 'SET_SELECTED_PRODUCT_NAME':
      return {
        ...state,
        selectedProductName: action.payload,
      };
    case 'SET_SINGLE_VALUE_ANSWERS':
      return {
        ...state,
        ...action.payload,
      };
    case 'SELECT_HEALTH_CONDITION':
      return {
        ...state,
        healthConditions: state.healthConditions?.includes(action.payload)
          ? state.healthConditions.filter(item => item !== action.payload)
          : [...(state.healthConditions || []), action.payload],
      };
    case 'SELECT_MULTIPLE_CHOICE_ANSWER':
      return setMultipleChoiceAnswer(state, action.payload);
    case 'CLEAR_PRIORITIES_TEXT_ANSWER':
      return clearPriorityTextAnswer(state, action.payload);
    case 'SET_MOBILE_NUMBER':
      return { ...state, mobileNumber: action.payload };
    case 'SET_ADDRESS_FIRST_LINE':
      if (action.payload.type === 'shipping') {
        return { ...state, addressFirstLine: action.payload.value };
      }
      return { ...state, billingAddressFirstLine: action.payload.value };
    case 'SET_ADDRESS_SECOND_LINE':
      if (action.payload.type === 'shipping') {
        return { ...state, addressSecondLine: action.payload.value };
      }
      return { ...state, billingAddressSecondLine: action.payload.value };
    case 'SET_ADDRESS_CITY':
      if (action.payload.type === 'shipping') {
        return { ...state, addressCity: action.payload.value };
      }
      return { ...state, billingAddressCity: action.payload.value };
    case 'SET_ADDRESS_POST_CODE':
      if (action.payload.type === 'shipping') {
        return { ...state, addressPostCode: action.payload.value };
      }
      return { ...state, billingAddressPostCode: action.payload.value };
    case 'SET_ADDRESS_COUNTRY':
      if (action.payload.type === 'shipping') {
        return { ...state, addressCountry: action.payload.value };
      }
      return { ...state, billingAddressCountry: action.payload.value };
    case 'SET_FIRST_NAME':
      return { ...state, firstName: action.payload };
    case 'SET_LAST_NAME':
      return { ...state, lastName: action.payload };
    case 'SET_NAME_PREFIX':
      return { ...state, namePrefix: action.payload };
    case 'SET_ALL':
      return {
        ...state,
        ...action.payload,
      };
    default:
      throw new Error();
  }
};

/**
 * This file contains utility functions for interacting with Braze.
 *
 * The reason for the funky import syntax is to allow appropriate Tree Shaking of the Braze SDK -
 * as described in their docs: https://www.braze.com/docs/developer_guide/platform_integration_guides/web/initial_sdk_setup/#ssr
 */

let brazeIsInitialized = false;

/**
 * Initializes the Braze SDK with the provided public key.
 * Only initializes Braze once.
 * @throws {Error} if Braze public key is not defined in the environment variables.
 */
const initializeBraze = async (): Promise<void> => {
  if (!process.env.brazePublicKey) {
    throw new Error('Braze public key is not defined');
  }

  // Only initialize Braze once
  if (brazeIsInitialized) {
    return;
  }

  const { initialize } = await import('./brazeExports');
  brazeIsInitialized = initialize(process.env.brazePublicKey, {
    baseUrl: 'sdk.fra-02.braze.eu',
  });
};

/**
 * Sets the user in Braze with the given user ID.
 * @param userId The ID of the user to set in Braze.
 * @returns A Promise that resolves when the user has been set in Braze, or immediately if Braze is not configured.
 */
export const setUserInBraze = async (userId: string): Promise<void> => {
  const { changeUser } = await import('./brazeExports');

  await initializeBraze();

  changeUser(userId);
};

/**
 * Logs a custom event in Braze.
 *
 * @param eventName - The name of the custom event to log.
 * @param userId - (Optional) The ID of the user to associate with the event.
 * @returns A Promise that resolves when the event has been logged.
 */
export const logEventInBraze = async (
  eventName: string,
  userId?: string,
): Promise<void> => {
  const { logCustomEvent } = await import('./brazeExports');

  await initializeBraze();

  if (userId) {
    await setUserInBraze(userId);
  }

  await logCustomEvent(eventName);
};

/**
 * Adds an alias to a user in Braze.
 * @param alias - The alias to add to the user.
 * @param label - The label for the alias.
 * @returns A Promise that resolves when the alias has been added.
 */
export const aliasUserInBraze = async (
  alias: string,
  label: string,
): Promise<void> => {
  const { getUser } = await import('./brazeExports');

  await initializeBraze();

  getUser()?.addAlias(alias, label);
};

/**
 * Set the user email in Braze.
 * @param email - The email to set for the user.
 * @returns A Promise that resolves when the email has been set.
 */
export const setEmailInBraze = async (email: string): Promise<void> => {
  const { getUser } = await import('./brazeExports');

  await initializeBraze();

  getUser()?.setEmail(email);
};

import { ErrorTracking } from '../../../common/errorTracking';

export const trackReferral = async ({
  referralId,
}: {
  referralId?: string;
}): Promise<void> => {
  if (!referralId) {
    return;
  }

  try {
    await fetch('/api/track-referral', {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        referralId,
        title: 'Completed sign up',
        properties: {},
        date: new Date(),
      }),
    });
  } catch (err) {
    ErrorTracking.track(err, {
      message: 'Failed to track referral',
    });
  }
};

import {
  HeightUnitType,
  WeightUnitType as GenericWeightUnitType,
} from '@nextTypes/units';

export type WeightUnitType = Omit<GenericWeightUnitType, 'lbs'>;

export type VitalityStateType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
  vitalityMembershipNumber: string;
  dietaryRequirements: string[];
  healthConditions: string[];
  priority: string;
  weightGainLifeEvents: string[];
  gender: string;
  smokingStatus: string;
  age: number;
  heightCm: number;
  heightFT: number;
  heightINCH: number;
  weightKg: number;
  weightLBS: number;
  weightST: number;
  heightUnit: HeightUnitType;
  weightUnit: WeightUnitType;
  goalWeightKg: number;
  goalWeightLBS: number;
  goalWeightST: number;
  percentageGoal: number;
  selectedStartDate: string;
  addressFirstLine: string;
  addressSecondLine: string;
  addressCity: string;
  addressPostCode: string;
  whoDoYouCookFor: string;
  howManyTimesDoYouCook: string;
};

export const initialState: VitalityStateType = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  phoneNumber: '',
  vitalityMembershipNumber: '',
  dietaryRequirements: [],
  healthConditions: [],
  priority: '',
  weightGainLifeEvents: [],
  gender: '',
  smokingStatus: '',
  heightCm: 0,
  heightFT: 0,
  heightINCH: 0,
  weightKg: 0,
  weightLBS: 0,
  weightST: 0,
  heightUnit: 'ft',
  weightUnit: 'kg',
  goalWeightKg: 0,
  goalWeightLBS: 0,
  goalWeightST: 0,
  percentageGoal: 0,
  age: 0,
  selectedStartDate: '',
  addressFirstLine: '',
  addressSecondLine: '',
  addressCity: '',
  addressPostCode: '',
  whoDoYouCookFor: '',
  howManyTimesDoYouCook: '',
};

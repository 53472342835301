import { VitalityStateType } from './state';
import { VitalitySignupActionType } from './actions';

export const initReducerState = (props: VitalityStateType): VitalityStateType =>
  props;

export type ReducerState = ReturnType<typeof initReducerState>;

export function reducer(
  state: VitalityStateType,
  action: VitalitySignupActionType,
): VitalityStateType {
  switch (action.type) {
    case 'SET_FIRST_NAME':
      return { ...state, firstName: action.payload };
    case 'SET_LAST_NAME':
      return { ...state, lastName: action.payload };
    case 'SET_EMAIL':
      return { ...state, email: action.payload };
    case 'SET_PHONE_NUMBER':
      return { ...state, phoneNumber: action.payload };
    case 'SET_VITALITY_MEMBERSHIP_NUMBER':
      return { ...state, vitalityMembershipNumber: action.payload };
    case 'SET_DIETARY_REQUIREMENTS':
      return { ...state, dietaryRequirements: action.payload };
    case 'SET_HEALTH_CONDITIONS':
      return { ...state, healthConditions: action.payload };
    case 'SET_PRIORITY':
      return { ...state, priority: action.payload };
    case 'SET_WEIGHT_GAIN_LIFE_EVENTS':
      return { ...state, weightGainLifeEvents: action.payload };
    case 'SET_GENDER':
      return { ...state, gender: action.payload };
    case 'SET_SMOKING_STATUS':
      return { ...state, smokingStatus: action.payload };
    case 'SET_PASSWORD':
      return { ...state, password: action.payload };
    case 'SET_PERCENTAGE_GOAL':
      return { ...state, percentageGoal: action.payload };
    case 'SET_SELECTED_START_DATE':
      return { ...state, selectedStartDate: action.payload };
    case 'SET_GOAL_WEIGHTS':
      return {
        ...state,
        goalWeightKg: action.payload.kg,
        goalWeightLBS: action.payload.lbs,
        goalWeightST: action.payload.st,
      };
    case 'SET_AGE':
      return { ...state, age: action.payload };
    case 'SET_HEIGHT':
      return {
        ...state,
        heightCm: action.payload.cm,
        heightFT: action.payload.ft,
        heightINCH: action.payload.inch,
      };
    case 'SET_HEIGHT_UNIT':
      return {
        ...state,
        heightUnit: action.payload,
      };
    case 'SET_WEIGHT_UNIT':
      return {
        ...state,
        weightUnit: action.payload,
      };
    case 'SET_WEIGHT':
      return {
        ...state,
        weightKg: action.payload.kg,
        weightLBS: action.payload.lbs,
        weightST: action.payload.st,
      };

    case 'SET_WHO_DO_YOU_COOK_FOR':
      return {
        ...state,
        whoDoYouCookFor: action.payload,
      };
    case 'SET_HOW_MANY_TIMES_DO_YOU_COOK':
      return {
        ...state,
        howManyTimesDoYouCook: action.payload,
      };
    case 'SET_ALL':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

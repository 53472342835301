/**
 * @deprecated OurPath content 1. No longer offered.
 */
const ourpathContent1Url = 'http://content.ourpath.co.uk';

/**
 * @deprecated OurPath content 2. No longer offered.
 */
export const ourpathContent2Url = 'http://content2.ourpath.co.uk';

/**
 * @deprecated OurPath content 3. No longer offered.
 */
const ourpathContent3Url = 'http://content3.ourpath.co.uk';

/**
 * @deprecated OurPath content 4. No longer offered.
 */
export const ourpathContent4Url = 'http://content4.ourpath.co.uk';

/**
 * @deprecated OurPath content 5. No longer offered.
 */
export const ourpathContent5Url = 'http://content5.ourpath.co.uk';

/**
 * @deprecated OurPath content 5 Australia. No longer offered.
 */
const ourpathContent5AustralianUrl = 'http://content5aus.ourpath.co.uk';

/**
 * @deprecated OurPath content 5 Digital. No longer offered.
 */
const ourpathContent5DigitalUrl = 'http://content5digital.ourpath.co.uk';

/**
 * @deprecated OurPath content 6. No longer offered.
 */
export const ourpathContent6Url = 'http://content6.ourpath.co.uk';

/**
 * @deprecated OurPath Self Guided content 6. No longer offered.
 */
export const selfGuidedOurpathContent6Url = 'http://content6sc.ourpath.co.uk';

/**
 * @deprecated OurPath content 6v2 self guided. No longer offered.
 */
export const ourpathContent6v2SelfGuided =
  'http://content6v2sg.secondnature.io';

/**
 * @deprecated Used for reboot follow-on programme. No longer offered.
 */
export const rebootUrl = 'http://content6v2reboot.secondnature.io';

/**
 * @deprecated Used for motivate follow-on programme. No longer offered.
 */
export const motivateUrl = 'http://content6v2motivate.secondnature.io';

/**
 * @deprecated Used for exercise follow-on programme. No longer offered.
 */
export const exerciseUrl = 'http://content6v2exercise.secondnature.io';

/**
 * @deprecated Used for american reboot follow-on programme. No longer offered.
 */
export const americanRebootUrl = 'http://content6v2usreboot.secondnature.io';

/**
 * @deprecated Used for american motivate follow-on programme. No longer offered.
 */
export const americanMotivateUrl =
  'http://content6v2usmotivate.secondnature.io';

/**
 * @deprecated Used for american exercise follow-on programme. No longer offered.
 */
export const americanExerciseUrl =
  'http://content6v2usexercise.secondnature.io';

/**
 * @deprecated Used for self-paced consumer programme. No longer offered.
 */
export const selfPacedConsumerUrl =
  'http://content6v2clselfpaced.secondnature.io';

/**
 * @deprecated OurPath original NHS content url. No longer offered.
 */
export const ourpathNHSUrl = 'http://contentnhs.ourpath.co.uk';

/**
 * @deprecated OurPath original NDPP content url. No longer offered.
 */
export const ourpathNDPPUrl = 'http://contentndpp.ourpath.co.uk';

/**
 * @deprecated OurPath T2D content url. No longer offered.
 */
const ourpathType2DiabetesUrl = 'http://contentt2d.ourpath.co.uk';

/**
 * @deprecated OurPath NDPP content 5 url. No longer offered.
 */
const ourpathContent5NDPPUrl = 'http://content5ndpp.ourpath.co.uk';

/**
 * @deprecated OurPath T2D content 5 url. No longer offered.
 */
const ourpathContent5Type2DiabetesUrl = 'http://content5t2dm.ourpath.co.uk';

/**
 * @deprecated OurPath content 6v2 consumer. No longer offered.
 */
export const ourpathContent6v2ConsumerUrl = 'http://content6v2.secondnature.io';

/**
 * @deprecated OurPath content 6v2 NDPP. No longer offered.
 */
export const ourpathContent6NDPPUrl = 'http://content6v2ndpp.secondnature.io';

/**
 * OurPath content 6v2 T2D. No longer offered.
 */
export const ourpathContent6Type2DiabetesUrl =
  'http://content6v2t2dm.secondnature.io';

/**
 * @deprecated OurPath content 6v2 Australian. No longer offered.
 */
const ourpathContent6v2AustralianUrl = 'http://content6v2aus.secondnature.io';

/**
 * @deprecated OurPath content 6v2 Digital. No longer offered.
 */
const ourpathContent6v2DigitalUrl = 'http://content6v2digital.secondnature.io';

/**
 * @deprecated OurPath content 6v2 Core Light. No longer offered.
 */
export const ourpathContent6v2CoreLightUrl =
  'http://content6v2cl.secondnature.io';

/**
 * @deprecated OurPath content 6v2 Core Light Australia. No longer offered.
 */
export const ourpathContent6v2CoreLightAustralianUrl =
  'http://content6v2auscl.secondnature.io';

/**
 * @deprecated OurPath content 6v2 Core Light America. No longer offered.
 */
export const ourpathContent6v2CoreLightAmericanUrl =
  'http://content6v2uscl.secondnature.io';

/**
 * @deprecated OurPath content 6v2 repeatable. No longer offered.
 */
const ourpathContent6v2RepeatableUrl =
  'http://content6v2repeatable.secondnature.io';

/**
 * WMS Level 1 self guided content URL.
 * Content omits any mention of coach. Daily messages come from habitologist bot.
 */
export const wms1SelfGuidedContentUrl = 'http://content6v2wms1.secondnature.io';

/**
 * WMS Level 3 content URL.
 * Was briefly used for Vitality groups before Vitality specific content was created.
 */
export const wms3CoachedContentUrl = 'http://content6v2wms3.secondnature.io';

/**
 * ContentUrl for content7. Was replaced by content7v2 but still
 * used for investor groups and as a default url in many places.
 * Should be retired and replaced with content7v2test541
 * as this content7 is out of date and without experiment X functionality.
 */
export const content7Url = 'https://content7.secondnature.io';

/**
 * Content URL for content7v2.
 */
export const content7v2 = 'https://content7v2.secondnature.io';

/**
 * @deprecated OurPath content 7 America. No longer offered.
 */
export const content7AmericanUrl = 'https://content7us.secondnature.io';

/**
 * @deprecated OurPath content 7v2 America. No longer offered.
 */
export const content7v2AmericanUrl = 'https://content7v2us.secondnature.io';

export const content7v2test541 = 'https://content7v2test541.secondnature.io';

/**
 * Content URL for the Wegovy Adapt medicated consumer programme.
 */
export const wegovyAdaptContentUrl = 'https://glp1.secondnature.io';

/**
 * Content URL for the Wegovy Grow medicated consumer programme.
 */
export const wegovyGrowContentUrl = 'https://glp1-grow.secondnature.io';

/**
 * Content URL for the Wegovy Progress medicated consumer programme.
 * Used to be Wegovy Maintain url but programme name has been changed to Wegovy Progress
 */
export const wegovyProgressContentUrl = 'https://glp1-maintain.secondnature.io';

/**
 * Content URL for the non-modular Mounjaro Adapt medicated consumer programme.
 */
export const mounjaroAdaptContentUrl = 'https://glp1-mounjaro.secondnature.io';

/**
 * Content URL for Vitality users, with Experiment X functionality.
 * This is the current content URL for Vitality users. It replaces the
 * WMS3 content URL that was previously used for Vitality ('http://content6v2wms3.secondnature.io')
 */
export const vitalityExperimentXUrl =
  'https://vitality-test541.secondnature.io';

/**
 * These are the contentURLs that are no longer supported for new groups.
 * Users may still have access to this content and be in old groups for
 * this content but we should not be creating new groups using these URLS.
 */
export const UNSUPPORTED_CONTENT_URLS = [
  exerciseUrl,
  motivateUrl,
  rebootUrl,
  americanRebootUrl,
  americanMotivateUrl,
  americanExerciseUrl,
  selfPacedConsumerUrl,
];

/**
 * List of all of our content URLs.
 */
export const CONTENT_URLS = [
  // core
  ourpathContent1Url,
  ourpathContent2Url,
  ourpathContent3Url,
  ourpathContent4Url,
  ourpathContent5Url,
  ourpathContent6Url,
  selfGuidedOurpathContent6Url,
  ourpathContent5AustralianUrl,
  ourpathContent5DigitalUrl,
  ourpathNHSUrl,
  ourpathNDPPUrl,
  ourpathContent5NDPPUrl,
  ourpathType2DiabetesUrl,
  ourpathContent5Type2DiabetesUrl,
  ourpathContent6v2ConsumerUrl,
  ourpathContent6NDPPUrl,
  ourpathContent6Type2DiabetesUrl,
  ourpathContent6v2AustralianUrl,
  ourpathContent6v2DigitalUrl,
  ourpathContent6v2SelfGuided,
  ourpathContent6v2CoreLightUrl,
  ourpathContent6v2CoreLightAustralianUrl,
  ourpathContent6v2CoreLightAmericanUrl,
  ourpathContent6v2RepeatableUrl,
  wms1SelfGuidedContentUrl,
  wms3CoachedContentUrl,
  rebootUrl,
  motivateUrl,
  exerciseUrl,
  selfPacedConsumerUrl,
  americanRebootUrl,
  americanMotivateUrl,
  americanExerciseUrl,
  content7Url,
  content7v2,
  content7AmericanUrl,
  content7v2AmericanUrl,
  content7v2test541,
  // growth
  'https://growth.secondnature.io',
  // maintain
  'https://maintain.secondnature.io',
  vitalityExperimentXUrl,
  // growth US (no group, no coach) - used by Elevance
  'https://growthNoGroupNoCoachUS.secondnature.io',
  // NDPP experiment x (test 541)
  'https://ndpp-test541.secondnature.io',
  // GLP-1s
  wegovyAdaptContentUrl,
  wegovyGrowContentUrl,
  wegovyProgressContentUrl,
  mounjaroAdaptContentUrl,
] as const;

/**
 * The type of a content URL.
 */
export type ContentUrlType = (typeof CONTENT_URLS)[number];

type MedicationAvailabilityResponse = {
  medication: string;
  hasAvailability: boolean;
};

/**
 * Fetch whether there are currently available signup slots for our medication programmes.
 *
 * @return {MedicationAvailabilityResponse[]} Whether there are available signup slots for our medication programmes.
 */
export const fetchMedicationAvailability = async (): Promise<
  MedicationAvailabilityResponse[]
> => {
  const response = await fetch(
    '/api/v2/plan-signup-slots/check-medication-availability',
  );

  if (!response.ok) {
    const errorMessage = 'Failed to fetch medication availability';
    throw new Error(errorMessage);
  }

  const {
    availability,
  }: {
    availability: MedicationAvailabilityResponse[];
  } = await response.json();
  return availability;
};

import { getBMI } from '../../../../src/utils/numberUtils';
import { ReferralType } from '../state/state';
import { checkValueForError } from '../../../nextUtils/generalUtils';
import {
  NDPP_CONTRACTS,
  HEIGHT_ERRORS,
  WEIGHT_ERRORS,
} from '../constants/validation';

export const validateBMI = ({
  weightKg,
  heightCm,
  contractName,
}: {
  weightKg: number;
  heightCm: number;
  contractName?: string;
}): string => {
  // Ensure BMI is not too low.
  const bmi = getBMI(weightKg, heightCm);
  const BMI_CUTOFF =
    contractName && NDPP_CONTRACTS.includes(contractName) ? 18.5 : 21;

  const bmiError =
    bmi && bmi < BMI_CUTOFF
      ? `Based on what you've told us, we don't think you would be applicable to undertake the Second Nature programme. The reason for this is your BMI is currently ${bmi}. This is too low for us to accept you on to the programme, seeing as how the primary outcome is weight loss. If you'd still like to take part in the programme, please send us an email at "support@secondnature.io". Or, if you think you may have entered your height and weight incorrectly above, please edit them and try pressing 'See my results' again.`
      : '';

  return bmiError;
};

export const validateHeightAndWeight = (
  heightCm: number,
  weightKg: number,
  nhsReferral: ReferralType | null,
): {
  heightError: string;
  weightError: string;
  bmiError: string;
  hasValidHeightAndWeight: boolean;
} => {
  // Validate height and weight fields which have more complex components.
  const heightError = checkValueForError(heightCm, HEIGHT_ERRORS) || '';
  const weightError = checkValueForError(weightKg, WEIGHT_ERRORS) || '';

  const bmiError = validateBMI({
    weightKg,
    heightCm,
    contractName: nhsReferral?.contract || '',
  });

  const hasValidHeightAndWeight = [heightError, weightError, bmiError].every(
    x => x === '' || x === null,
  );

  return { heightError, weightError, bmiError, hasValidHeightAndWeight };
};

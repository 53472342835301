export const setLocalStorage = <T>(key: string, value: T): void => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

export const getLocalStorage = <T>(key: string, initialValue: T): T => {
  try {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  } catch (e) {
    return initialValue;
  }
};

export const clearLocalStorage = (key: string): void => {
  try {
    window.localStorage.removeItem(key);
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

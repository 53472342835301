import { FieldErrors } from 'react-hook-form';

export const getErrorMessage = (errors: FieldErrors, key: string): string => {
  if (!errors) {
    return '';
  }

  const error = errors[key]?.message;

  return typeof error === 'string' ? error : '';
};

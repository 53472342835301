import {
  createContext,
  useReducer,
  useContext,
  useEffect,
  ReactNode,
  Dispatch,
} from 'react';

import {
  getLocalStorage,
  setLocalStorage,
} from '../NhsSignup/helpers/localStorage';
import {
  ActionDispatch,
  NHSSignupActionType,
} from '../NhsSignup/state/actions';
import { reducer, ReducerState } from '../NhsSignup/state/reducer';
import { initialState, NHSStateType } from '../NhsSignup/state/state';

const StateContext = createContext<NHSStateType | undefined>(undefined);
const DispatchContext = createContext<ActionDispatch | undefined>(undefined);

function NHSSignupProvider({ children }: { children: ReactNode }): JSX.Element {
  const [state, dispatch] = useReducer(
    reducer,
    getLocalStorage('cached-sign-up', initialState),
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { password, ...stateWithoutPassword } = state;

    setLocalStorage('cached-sign-up', stateWithoutPassword);
  }, [state]);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
}

const useNHSContext = (): ReducerState => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('useNHSContext must be used within a StateProvider');
  }
  return context;
};

const useNHSDispatchContext = (): Dispatch<NHSSignupActionType> => {
  const context = useContext(DispatchContext);
  if (context === undefined) {
    throw new Error(
      'useDispatchContext must be used within a DispatchProvider',
    );
  }
  return context;
};

export const useNHS = (): {
  state: NHSStateType;
  dispatch: Dispatch<NHSSignupActionType>;
} => {
  const state = useNHSContext();
  const dispatch = useNHSDispatchContext();

  return { state, dispatch };
};

export { NHSSignupProvider as default };

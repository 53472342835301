import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from 'react';

import { RestartActionType, ReducerState } from './types';
import { initialState, reducer } from './reducer';
import {
  getLocalStorage,
  setLocalStorage,
} from '@components/NhsSignup/helpers';

export const RestartContext = createContext<ReducerState | undefined>(
  undefined,
);

export const RestartDispatchContext = createContext<
  Dispatch<RestartActionType> | undefined
>(undefined);

export const useRestartContext = (): ReducerState => {
  const context = useContext(RestartContext);
  if (context === undefined) {
    throw new Error('useRestartContext must be used within a StateProvider');
  }
  return context;
};

export const useRestartDispatch = (): Dispatch<RestartActionType> => {
  const context = useContext(RestartDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useRestartDispatch must be used within a DispatchProvider',
    );
  }
  return context;
};

export const RestartProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [state, dispatch] = useReducer(
    reducer,
    getLocalStorage('cached-restart-data', initialState),
  );

  useEffect(() => {
    setLocalStorage('cached-restart-data', state);
  }, [state]);

  return (
    <RestartDispatchContext.Provider value={dispatch}>
      <RestartContext.Provider value={state}>
        {children}
      </RestartContext.Provider>
    </RestartDispatchContext.Provider>
  );
};

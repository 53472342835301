import { differenceInYears } from 'date-fns';
import { ChangeEvent } from 'react';

import { OldQuizAnswersType, HeightAndWeightFormType } from '@nextTypes/quiz';

import { getBMI } from '../../src/utils/numberUtils';
import { QuizActionType } from '@components/Quiz/state/actions';

export const calculateWeightLossGoals = ({
  weightLossGoalKg,
  weightKg,
  heightCm,
  hasWeightGoalInMind,
  goals,
}: {
  weightLossGoalKg: number | null | undefined;
  weightKg: number | null | undefined;
  heightCm: number | null | undefined;
  hasWeightGoalInMind: boolean | null | undefined;
  goals?: string | null;
}): {
  goalKg?: number;
  percentageGoal?: number;
  goalKgLongTerm?: number;
} => {
  if (
    weightKg === undefined ||
    weightKg === null ||
    heightCm === undefined ||
    heightCm === null
  ) {
    return {
      goalKg: undefined,
      percentageGoal: undefined,
      goalKgLongTerm: undefined,
    };
  }
  let percentageGoalLongTerm: number | null = null;
  const currentBMI = getBMI(weightKg, heightCm) as number;
  let percentageGoal: number | null = null;

  if (currentBMI > 35) {
    percentageGoal = 0.06;
    percentageGoalLongTerm = 0.15;
  } else if (currentBMI > 30) {
    percentageGoal = 0.05;

    percentageGoalLongTerm = 0.1;
  } else if (currentBMI > 25) {
    percentageGoal = 0.03;

    percentageGoalLongTerm = 0.07;
  } else if (currentBMI > 23) {
    percentageGoal = 0.02;

    percentageGoalLongTerm = 0.04;
  } else if (currentBMI <= 23) {
    percentageGoal = 0.02;
    percentageGoalLongTerm = 0.03;
  } else {
    percentageGoal = 0.05;
    percentageGoalLongTerm = 0.1;
  }

  switch (goals) {
    case 'high':
      percentageGoal += 0.01;
      break;
    case 'low':
      percentageGoal -= 0.01;
      break;
    default:
      break;
  }

  let goalKg = Math.round(weightKg * (1 - percentageGoal) * 10) / 10;

  // set the default, recommended long term weight loss goal
  let goalKgLongTerm =
    Math.round(weightKg * (1 - percentageGoalLongTerm) * 10) / 10;

  // if they set a goal, override the default
  if (
    weightLossGoalKg !== null &&
    weightLossGoalKg !== undefined &&
    weightLossGoalKg !== 0 &&
    hasWeightGoalInMind
  ) {
    if (weightLossGoalKg < goalKgLongTerm) {
      goalKgLongTerm = weightLossGoalKg;
    } else {
      goalKg = Math.round(weightLossGoalKg * 10) / 10;
    }
  }

  return {
    goalKg,
    percentageGoal,
    goalKgLongTerm,
  };
};

export const calculateWeightGoalsFromAnswers = (
  answers: OldQuizAnswersType,
  values: HeightAndWeightFormType,
): {
  goalKg?: number | undefined;
  percentageGoal?: number | undefined;
  goalKgLongTerm?: number | undefined;
} => {
  const { goals } = answers;
  const { weightLossGoalKg, weightKg, heightCm, hasWeightGoalInMind } = values;

  const result = calculateWeightLossGoals({
    goals,
    weightLossGoalKg,
    weightKg,
    heightCm,
    hasWeightGoalInMind,
  });

  return result;
};

export const convertAnswerObjectToArray = (answers: {
  [key: string]: boolean | undefined;
}): string[] => {
  const array: string[] = [];
  for (const key in answers) {
    if (answers[key]) {
      array.push(key);
    }
  }
  return array;
};

/**
 * Converts an array of strings to an object where each string becomes a key with a value of true.
 * For example, it converts ['metformin', 'insulin'] to { metformin: true, insulin: true }
 *
 * @param {string[]} state - An array of strings to be converted into an object.
 * @returns {Record<string, boolean>} An object where each key is a string from the input array and each value is true.
 */
export const convertArrayOfSelectedValuesToObject = (
  state: string[],
): {
  [key: string]: boolean;
} => {
  const obj: Record<string, boolean> = {};
  state.forEach((item: string) => {
    obj[item] = true;
  });
  return obj;
};

export const getNumericInputValue = (
  inputEvent: ChangeEvent<HTMLInputElement>,
): number => {
  const inputValue = parseInt(inputEvent?.target.value);
  return isNaN(inputValue) ? 0 : inputValue;
};

export const calculateAge = (dateOfBirth: string): number =>
  differenceInYears(new Date(), new Date(dateOfBirth));

export const updateMultiChoiceQuestion = <T extends QuizActionType>(
  existingValues: string[],
  value: string,
  dispatch: (action: T) => void,
  actionType: T['type'],
  numberOfAnswersAllowed?: number,
): void => {
  if (existingValues.includes(value)) {
    dispatch({
      type: actionType,
      payload: existingValues.filter(existingValue => existingValue !== value),
    } as T);
  } else if (existingValues.length === numberOfAnswersAllowed) {
    return;
  } else {
    dispatch({
      type: actionType,
      payload: [...existingValues, value],
    } as T);
  }
};

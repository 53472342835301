import { NHSStateType } from '@components/NhsSignup/state/state';
import { NHSSignupActionType } from './actions';

export const initReducerState = (props: NHSStateType): NHSStateType => props;

export type ReducerState = ReturnType<typeof initReducerState>;

// eslint-disable-next-line complexity
export function reducer(
  state: NHSStateType,
  action: NHSSignupActionType,
): NHSStateType {
  switch (action.type) {
    case 'SET_HEIGHT_UNIT':
      return { ...state, heightUnit: action.payload };
    case 'SET_HEALTH_CONDITIONS':
      return { ...state, healthConditions: action.payload };
    case 'SET_WEIGHT_UNIT':
      return { ...state, weightUnit: action.payload };
    case 'SET_NHS_REFERRAL':
      return { ...state, nhsReferral: action.payload };
    case 'SET_EMAIL':
      return { ...state, email: action.payload };
    case 'SET_FIRST_NAME':
      return { ...state, firstName: action.payload };
    case 'SET_LAST_NAME':
      return { ...state, lastName: action.payload };
    case 'SET_PASSWORD':
      return { ...state, password: action.payload };
    case 'SET_PHONE_NUMBER':
      return { ...state, phoneNumber: action.payload };
    case 'SET_GENDER':
      return { ...state, gender: action.payload };
    case 'SET_AGE':
      return { ...state, age: action.payload };
    case 'SET_HEIGHT':
      return {
        ...state,
        heightCm: action.payload.cm,
        heightFT: action.payload.ft,
        heightINCH: action.payload.inch,
      };
    case 'SET_WEIGHT':
      return {
        ...state,
        weightKg: action.payload.kg,
        weightLBS: action.payload.lbs,
        weightST: action.payload.st,
      };
    case 'SET_SMOKING_STATUS':
      return { ...state, smokingStatus: action.payload };
    case 'SET_HAS_BEEN_REFERRED_TO_STOP_SMOKING_SERVICE':
      return { ...state, hasBeenReferredToStopSmokingService: action.payload };
    case 'SET_ETHNICITY':
      return { ...state, ethnicity: action.payload };
    case 'SET_PERCENTAGE_GOAL':
      return { ...state, percentageGoal: action.payload };
    case 'SET_GOAL_WEIGHTS':
      return {
        ...state,
        goalWeightKg: action.payload.kg,
        goalWeightLBS: action.payload.lbs,
        goalWeightST: action.payload.st,
      };
    case 'SET_HEALTH_BARRIERS':
      return { ...state, healthBarriers: action.payload };
    case 'SET_REGULARLY_RECORD':
      return { ...state, regularlyRecord: action.payload };
    case 'SET_USE_PHONE_AND_APP':
      return { ...state, usePhoneAndApp: action.payload };
    case 'SET_WILLING_TO_COMMUNICATE':
      return { ...state, willingToCommunicate: action.payload };
    case 'SET_READY_TO_BE_SUCCESSFUL':
      return { ...state, readyToBeSuccessful: action.payload };
    case 'SET_NAME_COMMITMENT':
      return { ...state, nameCommitment: action.payload };
    case 'SET_ADDRESS_FIRST_LINE':
      return { ...state, addressFirstLine: action.payload };
    case 'SET_ADDRESS_SECOND_LINE':
      return { ...state, addressSecondLine: action.payload };
    case 'SET_ADDRESS_THIRD_LINE':
      return { ...state, addressThirdLine: action.payload };
    case 'SET_ADDRESS_CITY':
      return { ...state, addressCity: action.payload };
    case 'SET_ADDRESS_POST_CODE':
      return { ...state, addressPostCode: action.payload };
    case 'SET_SELECTED_START_DATE':
      return { ...state, selectedStartDate: action.payload };
    case 'SET_HAS_VALID_PHONE':
      return { ...state, hasValidPhone: action.payload };
    case 'SET_HEALTH_SCORE':
      return { ...state, healthScore: action.payload };
    case 'SET_OPT_IN_QUESTIONNAIRE':
      return { ...state, optInQuestionnaire: action.payload };
    case 'SET_MOBILITY':
      return { ...state, mobility: action.payload };
    case 'SET_CARE_FOR_YOURSELF':
      return { ...state, careForYourself: action.payload };
    case 'SET_PERFORM_USUAL_ACTIVITIES':
      return { ...state, performUsualActivities: action.payload };
    case 'SET_PAIN_OR_DISCOMFORT':
      return { ...state, painOrDiscomfort: action.payload };
    case 'SET_ANXIETY_OR_DEPRESSION':
      return { ...state, anxietyOrDepression: action.payload };
    case 'SET_TAKING_MEDICATION':
      return { ...state, takingMedication: action.payload };
    case 'SET_IS_ON_HYPO_MEDICATION':
      return { ...state, isOnHypoMedication: action.payload };
    case 'SET_DEPRESSION':
      return { ...state, depression: action.payload };
    case 'SET_ANXIETY':
      return { ...state, anxiety: action.payload };
    case 'SET_WORRY_CONTROL':
      return { ...state, worryControl: action.payload };
    case 'SET_ALCOHOL_UNITS_WEEKLY':
      return { ...state, alcoholUnits: action.payload };
    case 'SET_PHYSICAL_ACTIVITY_MINUTES_WEEKLY':
      return { ...state, physicalActivityMinutes: action.payload };
    case 'SET_ALCOHOL_FREQUENCY':
      return { ...state, auditC1AlcoholFrequency: action.payload };
    case 'SET_ALCOHOL_UNITS':
      return { ...state, auditC2AlcoholUnits: action.payload };
    case 'SET_BINGE_DRINKING_FREQUENCY':
      return { ...state, auditC3BingeDrinkingFrequency: action.payload };
    case 'SET_NO_PLEASURE':
      return { ...state, noPleasure: action.payload };
    case 'SET_ALL':
      return { ...state, ...action.payload };
    default:
      throw new Error('Invalid action type');
  }
}

import Cookies from 'js-cookie';

import { countriesAvailableByPlan } from '../../src/constants';
import {
  CountryCodeType,
  NEXTJS_LOCALES,
  LocaleType,
} from '../../src/constants/internationalConstants';
import { pageFlows } from '../middlewareConstants';

export const isNumeric = (number: string | number | null): boolean =>
  !isNaN(parseFloat(number as string)) && isFinite(number as number);

export const vitalityPromoCodeRegex = new RegExp(/^vitalitya/);
export const elevancePromoCodeRegex = new RegExp(/^elevance-/);

const getPathArrayAndLocaleSlug = (
  path: string,
): { pathArray: string[]; localeSlug: string | undefined } => {
  // Split the path into strings in an array.
  // Example: path = '/guides/category/myth-busting' => pathArray = ["guides", "category", "myth busting"]
  // Example: path = '/careers' => pathArray = ["about us"]
  // Example: path = '/' => pathArray = [""]
  const pathArray = path
    .split('?')[0] // Remove any query from the path
    .slice(1) // Replace the initial "/"
    .replace(/-/g, ' ') // Replace any hyphens with spaces
    .split('/') // Split path into an array
    .map(partOfPath => partOfPath.split('#', 1)[0]); // Remove characters after "#" in each element of array

  const localeSlug = NEXTJS_LOCALES.find(locale => locale === pathArray[0]);

  // Remove the Locale slug from pathArray if present
  if (localeSlug) {
    pathArray.shift();
  }

  return { pathArray, localeSlug };
};

export const getPartnershipPageViewProperties = (
  path: string,
  search: string,
): {
  [key: string]: string;
} => {
  const { pathArray } = getPathArrayAndLocaleSlug(path);

  const url = path.split('?')[0];

  const contract = pathArray[1];
  const variant = Cookies.get(`bucket-${contract}`);
  const pageName = pathArray[2];
  const searchParams = new URLSearchParams(search);
  const nhscode = searchParams.get('nhscode');

  return {
    ...(url && { url }),
    ...(!!variant && { variant }),
    ...(contract && { contract }),
    ...(pageName && { 'Page name': pageName }),
    ...(nhscode && { nhscode }),
  };
};

/**
 * Retrieves the user's bucket information for all defined page flows.
 *
 * This function iterates over the `pageFlows` array and checks if the user has a corresponding
 * bucket cookie for each flow. If a bucket value is found, it adds a new property to the
 * returned object with the key `variant-{cookieName}` and the corresponding bucket value.
 *
 * @returns An object containing the user's bucket information for each page flow.
 *          The object keys are in the format `variant-{cookieName}`, and the values are the
 *          corresponding bucket values.
 *
 * @example
 * const userBuckets = getUserBuckets();
 * // Output example:
 * // {
 * //   'variant-bucket-reed': 'a',
 * //   'variant-bucket-ndpp-full-delivery': 'a',
 * //   'variant-bucket-consumer-quiz': 'control',
 * //   'variant-bucket-consumer-quiz-us': 'us-control'
 * // }
 */
export const getUserBuckets = (): { [key: string]: string } => {
  const userBuckets: { [key: string]: string } = {};

  pageFlows.forEach(flow => {
    const bucketValue = Cookies.get(flow.cookieName);
    if (bucketValue) {
      userBuckets[`variant-${flow.cookieName}`] = bucketValue;
    }
  });

  return userBuckets;
};

export const getQuizFlowPageViewProperties = (
  path: string,
): {
  [key: string]: string;
} => {
  const { pathArray } = getPathArrayAndLocaleSlug(path);

  const url = path.split('?')[0];

  const userBuckets = getUserBuckets();
  const pageName = pathArray[1];

  return {
    ...(url && { url }),
    ...userBuckets,
    ...(pageName && { 'Page name': pageName }),
  };
};
export type MixpanelEventDetails = {
  title: string;
  category?: string;
  guideTitle?: string;
  subpage?: string;
  referralCode?: string;
  url?: string;
  pageName?: string;
  subSections?: string[];
  locale?: LocaleType;
};

export const getMixpanelEventDetails = (
  path: string,
): MixpanelEventDetails[] => {
  const { pathArray, localeSlug } = getPathArrayAndLocaleSlug(path);

  const locale = localeSlug || 'gb';

  // Full URL minus query string
  const url = path.split('?')[0];
  if (
    pathArray.length === 0 ||
    (pathArray.length === 1 && pathArray[0] === '')
  ) {
    // Example: path = '/au' => title = 'home'
    return [
      { title: 'Viewed home page', ...getUserBuckets() },
      {
        title: 'Viewed page',
        pageName: `home`,
        url,
        locale,
        ...getUserBuckets(),
      },
    ];
  }

  if (pathArray.length === 1) {
    // Example: path = '/careers' => title = "Viewed about us page"
    // Example: path = '/guides' => title = "Viewed guides page"
    if (pathArray[0] !== '') {
      return [
        { title: `Viewed ${pathArray[0]} page` },
        { title: 'Viewed page', pageName: pathArray[0], url, locale },
      ];
    }
    return [
      { title: 'Viewed home page' },
      { title: 'Viewed page', pageName: `home`, url, locale },
    ];
  }
  // If in the guides section of the website, treat seperately
  if (pathArray[0] === 'guides') {
    if (pathArray[1] === 'category') {
      // If path is guides/category then the page is a guides category page
      // Example: path = '/guides/category/myth-busting' => {title: "Viewed guides category page", category: "myth busting"}
      return [
        {
          title: 'Viewed guides category page',
          category: pathArray[2],
        },
        {
          title: 'Viewed page',
          pageName: `Guides - ${pathArray.slice(2).join('/')} category`,
          subSections: ['guides', pathArray[2]],
          url,
          locale,
        },
      ];
    }
    // If the path is /guides but is not followed by 'category', then it is a guide page.
    // Example: path = '/guides/myth-busting/detox-diets' => {title: "Viewed guide", category: "myth busting", guideTitle: "detox diets"}
    return [
      {
        title: 'Viewed guide',
        guideTitle: pathArray[pathArray.length - 1],
        category: pathArray[1],
      },
      {
        title: 'Viewed page',
        pageName: pathArray[pathArray.length - 1],
        subSections: ['guides', pathArray[1]],
        url,
        locale,
      },
    ];
  } else if (pathArray[0] === 'invite') {
    // These are the invitation intro pages, which take the form '/invite/:referralCode'
    // Example: path = 'invite/test' => {title: "Viewed invite page", referralCode: 'test'}
    return [
      {
        title: 'Viewed invite page',
        referralCode: pathArray[1],
      },
      {
        title: 'Viewed page',
        pageName: 'invite',
        referralCode: pathArray[1],
        url,
        locale,
      },
    ];
  } else if (pathArray[0] === 'restart') {
    return [
      {
        title: `Viewed restart subpage`,
        subpage: pathArray[pathArray.length - 1],
      },
      {
        title: 'Viewed page',
        pageName: 'Restart',
        subpage: pathArray[pathArray.length - 1],
        url,
        locale,
      },
    ];
  }
  // Not currently required, but if in future there is a page such as /careers/team
  // This would show as {title: "Viewed about us subpage", subpage: "team"}
  return [
    {
      title: `Viewed ${pathArray[0]} subpage`,
      subpage: pathArray[pathArray.length - 1],
    },
    {
      title: 'Viewed page',
      pageName: pathArray[0],
      subpage: pathArray[pathArray.length - 1],
      url,
      locale,
    },
  ];
};

export const isDigitalOnly = (countryCode: CountryCodeType): boolean =>
  !countriesAvailableByPlan.coreNoTech.includes(countryCode) &&
  !countriesAvailableByPlan.coreTech.includes(countryCode) &&
  !!countriesAvailableByPlan.digital.includes(countryCode);

export const getHasVitalityPromoCode = (): boolean => {
  const promoCode = Cookies.get('promo-code');
  if (!promoCode) {
    return false;
  }
  return vitalityPromoCodeRegex.test(promoCode);
};

export const getHasElevancePromoCode = (): boolean => {
  const promoCode = Cookies.get('promo-code');
  if (!promoCode) {
    return false;
  }
  return elevancePromoCodeRegex.test(promoCode);
};

/**
 * Returns the value of a cookie
 * @param {string} name Cookie Name
 */
export const getCookie = (name: string): string | null | undefined => {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return v ? v[2] : null;
};

export const objectKeysToArr = (obj?: {
  [key: string]: boolean | undefined;
}): string[] => {
  if (!obj) {
    return [];
  }
  const arrOfKeys = Object.keys(obj).reduce((arr: string[], key) => {
    if (obj[key] === true) {
      arr.push(key);
    }
    return arr;
  }, []);

  return arrOfKeys;
};

export const checkValueForError = (
  value: number | null | undefined,
  potentialErrors: {
    condition: (value: number) => boolean;
    message: string;
  }[],
): string | null => {
  if (value == undefined || !potentialErrors || !potentialErrors.length) {
    return null;
  }
  for (const error of potentialErrors) {
    if (error.condition(value)) {
      return error.message;
    }
  }
  return null;
};

/**
 * Maps the keys of an enum as an array
 *
 * Inspired by this post: https://github.com/microsoft/TypeScript/issues/30611
 *
 * @param {T} genericEnum - The enum to map
 * @returns {(keyof typeof genericEnum)[]} - An array of the keys of the enum
 */
export const mapEnumKeysAsArray = <T extends { [id: string]: string }>(
  genericEnum: T,
): (keyof typeof genericEnum)[] => Object.keys(genericEnum);

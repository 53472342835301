import Cookies from 'js-cookie';

import { NEXTJS_DEFAULT_LOCALE } from '@constants/internationalConstants';
import { AbTestsType } from '../types/tracking';

/**
 * Returns an object with ALL ab test cookies
 * e.g. {
 *   219: 2,
 *   419: 1,
 * }
 */
export const getABTestCookies = (locale: string | undefined): AbTestsType => {
  const abTests = {} as AbTestsType;

  // We only run AB tests on UK users at the moment
  // This filtering is actually applied when the AB test cookies are set
  // but there are some legacy cookies that were set before these checks were added so we filter here too
  if (locale !== NEXTJS_DEFAULT_LOCALE) {
    return abTests;
  }

  const arrayOfTests = document.cookie.match(/(ab-test-[0-9]*=[^;]*)/g) || [];

  for (let i = 0; i < arrayOfTests.length; i++) {
    const test = arrayOfTests[i];

    const [key, value] = test.split('=');
    const testId = key.match(/ab-test-(\d*)/);
    if (!testId) {
      continue;
    }

    const testID = testId[1];

    const description = Cookies.get(`ab-test-${testID}-description`) || '';
    const dateAdded = Cookies.get(`ab-test-${testID}-date-added`);

    abTests[parseInt(testID)] = {
      testID,
      variant: value,
      description,
      dateAdded:
        typeof dateAdded === 'string' ? new Date(dateAdded) : undefined,
    };
  }
  return abTests;
};

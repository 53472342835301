import { getGLP1BaseCriteria } from './baseEligibility';

import {
  reduceEligibilityCriteria,
  EligibilityCriteria,
  EligibilityResult,
} from '../../components/Quiz/QuizUtils';
import { QuizStateType } from '../../components/Quiz/state/state';
import { getBMI } from '../../../src/utils/numberUtils';
import { convertAnswerObjectToArray } from '../quizUtils';

type Type2DiabetesScreens =
  | 'diabetesManagement'
  | 'type2DiabetesMedication'
  | 'diabetesMedicationPrescriber'
  | 'diabetesDoctorsAppointment'
  | 'diabetesComplications';

/**
 * @param {Partial<QuizStateType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user has type 2 diabetes.
 */
export const hasType2Diabetes = (answers: Partial<QuizStateType>): boolean =>
  answers.diabetesStatus === 'type2';

/**
 * @param {Partial<QuizStateType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user is taking diabetes medication.
 */
export const isTakingDiabetesMedication = (
  answers: Partial<QuizStateType>,
): boolean => !!answers.diabetesManagement?.includes('medication');

/**
 * @param {Partial<QuizStateType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user is managing their diabetes without medication.
 */
export const managesDiabetesWithoutMedication = (
  answers: Partial<QuizStateType>,
): boolean => {
  const { diabetesManagement = [] } = answers;
  return (
    !diabetesManagement.includes('medication') &&
    !diabetesManagement.includes('none') &&
    diabetesManagement.length > 0
  );
};

/**
 * @param {Partial<QuizStateType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user is managing their diabetes with **only** metformin.
 */
export const takesMetforminExclusively = (
  answers: Partial<QuizStateType>,
): boolean =>
  answers.type2DiabetesMedications?.length === 1 &&
  answers.type2DiabetesMedications[0] === 'metformin';

/**
 * @param {Partial<QuizStateType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user's diabetes medication was not prescribed by a specialist.
 */
export const diabetesMedicationNotPrescribedBySpecialist = (
  answers: Partial<QuizStateType>,
): boolean =>
  !!answers?.diabetesMedicationPrescriber &&
  answers.diabetesMedicationPrescriber !== 'specialist';

/**
 * @param {Partial<QuizStateType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user has no upcoming diabetes appointments.
 */
export const noUpcomingDiabetesAppointment = (
  answers: Partial<QuizStateType>,
): boolean => answers.hasDiabetesDoctorsAppointment === false;

/**
 * @param {Partial<QuizStateType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user is not ineligible for the GLP-1 programme based on the diabetes medication that they're taking.
 */
export const meetsDiabetesMedicationCriteria = (
  answers: Partial<QuizStateType>,
): boolean =>
  isTakingDiabetesMedication(answers) &&
  takesMetforminExclusively(answers) &&
  diabetesMedicationNotPrescribedBySpecialist(answers);

/**
 * @param {Partial<QuizStateType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user has no diabetes complications.
 */
export const noDiabetesComplications = (
  answers: Partial<QuizStateType>,
): boolean => {
  const filteredComplications = answers.diabetesComplications?.filter(
    diabetesComplication => diabetesComplication !== 'none',
  );
  return !filteredComplications || filteredComplications.length === 0;
};

/**
 * Determines whether a user should see a question based on their answers to the diabetes questions.
 */
export const getType2DiabetesQuestionRenderCondition = (
  question: Type2DiabetesScreens,
  answers: QuizStateType,
): boolean => {
  switch (question) {
    case 'diabetesManagement':
      return hasType2Diabetes(answers);

    case 'type2DiabetesMedication':
      return hasType2Diabetes(answers) && isTakingDiabetesMedication(answers);

    case 'diabetesMedicationPrescriber':
      return (
        hasType2Diabetes(answers) &&
        isTakingDiabetesMedication(answers) &&
        takesMetforminExclusively(answers)
      );

    case 'diabetesDoctorsAppointment':
      return (
        hasType2Diabetes(answers) &&
        (managesDiabetesWithoutMedication(answers) ||
          meetsDiabetesMedicationCriteria(answers))
      );

    case 'diabetesComplications':
      return (
        hasType2Diabetes(answers) &&
        noUpcomingDiabetesAppointment(answers) &&
        (managesDiabetesWithoutMedication(answers) ||
          meetsDiabetesMedicationCriteria(answers))
      );

    default:
      return false;
  }
};

const getType2DiabetesLifestyleCriteria = (
  answers: Partial<QuizStateType>,
): EligibilityCriteria[] => {
  const {
    diabetesStatus,
    diabetesManagement = {},
    hasDiabetesDoctorsAppointment,
    diabetesComplications = {},
  } = answers;

  return [
    {
      key: 'diabetesStatus',
      value: diabetesStatus,
      isValid: hasType2Diabetes(answers),
    },
    {
      key: 'hasDiabetesDoctorsAppointment',
      value: hasDiabetesDoctorsAppointment,
      isValid: noUpcomingDiabetesAppointment(answers),
    },
    {
      key: 'diabetesComplications',
      value: convertAnswerObjectToArray(diabetesComplications),
      isValid: noDiabetesComplications(answers),
    },
    {
      key: 'diabetesManagement',
      value: convertAnswerObjectToArray(diabetesManagement),
      isValid: managesDiabetesWithoutMedication(answers),
    },
  ];
};

/**
 * Determines whether a user qualifies for the GLP1 programme despite having type 2 diabetes.
 *
 * @param {Pick<QuizStateType>} answers - The user's answers to the quiz questions.
 * @param {string} [locale] - The active locale.
 * @returns {EligibilityResult} Whether the user qualifies for GLP1 programme and any failing criteria.
 */
export const getType2DiabetesMedicationCriteria = (
  answers: Partial<QuizStateType>,
): EligibilityCriteria[] => {
  const {
    diabetesStatus,
    diabetesManagement = [],
    type2DiabetesMedications = [],
    diabetesMedicationPrescriber,
    hasDiabetesDoctorsAppointment,
    diabetesComplications = [],
  } = answers;

  return [
    {
      key: 'diabetesStatus',
      value: diabetesStatus,
      isValid: hasType2Diabetes(answers),
    },
    {
      key: 'hasDiabetesDoctorsAppt',
      value: hasDiabetesDoctorsAppointment,
      isValid: noUpcomingDiabetesAppointment(answers),
    },
    {
      key: 'diabetesComplications',
      value: diabetesComplications,
      isValid: noDiabetesComplications(answers),
    },
    {
      key: 'diabetesManagement',
      value: diabetesManagement,
      isValid: isTakingDiabetesMedication(answers),
    },
    {
      key: 'type2DiabetesMedication',
      value: type2DiabetesMedications,
      isValid: takesMetforminExclusively(answers),
    },
    {
      key: 'diabetesMedicationPrescriber',
      value: diabetesMedicationPrescriber,
      isValid: diabetesMedicationNotPrescribedBySpecialist(answers),
    },
  ];
};

/**
 * Determines whether a user qualifies for the GLP1 programme based on:
 * - The base GLP1 criteria
 * - The user's type 2 diabetes lifestyle management
 * - The user's type 2 diabetes medication
 *
 * @param {Pick<QuizStateType>} answers - The user's answers to the quiz questions.
 * @param {string} [locale] - The active locale.
 * @returns {EligibilityResult} Whether the user qualifies for GLP1 programme and any failing criteria.
 */
export const qualifiesForGLP1ProgrammeWithType2Diabetes = (
  answers: Pick<
    QuizStateType,
    | 'weightKg'
    | 'heightCm'
    | 'healthConditions'
    | 'healthConditionsComorbidities'
    | 'personalHabits'
    | 'pregnancy'
    | 'diabetesStatus'
    | 'dateOfBirth'
    | 'gender'
  >,
  locale?: string,
): EligibilityResult => {
  /**
   * The type 2 diabetes criteria is the same as the base GLP1 criteria, except
   * we remove the requirement on the "I don't have diabetes or a family
   * history of diabetes" answer.
   * Instead, we allow a user to answer with "type 2 diabetes", if they meet
   * certain conditions for how they're managing their condition - i.e either
   * they manage it without medication, or they manage it with metformin.
   */
  const glp1CriteriaWithoutDiabetesStatusOrBmi = getGLP1BaseCriteria(
    answers,
    locale,
  ).filter(
    criterion => criterion.key !== 'diabetesStatus' && criterion.key !== 'bmi',
  );

  // For eligibility on the GLP1 programme, users can have a BMI over 27
  // instead of over 30
  const bmi = getBMI(answers.weightKg || 0, answers.heightCm || 0);
  const glp1CriteriaWithUpdatedBmi = [
    ...glp1CriteriaWithoutDiabetesStatusOrBmi,
    {
      key: 'bmi',
      value: bmi,
      isValid: !!bmi && bmi >= 27,
    },
  ];

  const type2DiabetesLifestyleEligibility = reduceEligibilityCriteria([
    ...glp1CriteriaWithUpdatedBmi,
    ...getType2DiabetesLifestyleCriteria(answers),
  ]);

  const type2DiabetesMedicationEligibility = reduceEligibilityCriteria([
    ...glp1CriteriaWithUpdatedBmi,
    ...getType2DiabetesMedicationCriteria(answers),
  ]);

  if (managesDiabetesWithoutMedication(answers)) {
    return type2DiabetesLifestyleEligibility;
  }

  return type2DiabetesMedicationEligibility;
};

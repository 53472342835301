export const passwordValidationRules = {
  required: 'A password is required',
  minLength: {
    value: 8,
    message: 'Your password must be at least 8 characters',
  },
};

export const phoneNumberValidationRules = {
  required: 'Your phone number is required',
  pattern: {
    message: 'Please enter a valid mobile number',
    value:
      /^\s*(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?\s*$/,
  },
};

export const emailValidationRules = {
  required: 'Your email is required',
  pattern: {
    value: /[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*/g,
    message: "That doesn't look right. Please check your email address.",
  },
};

export const vitalityEmployeeEmailValidationRules = {
  required: 'Your email is required',
  pattern: {
    value: /[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@vitality\.co\.uk$/g,
    message:
      "That doesn't look right. Please check your email address is your Vitality email address.",
  },
};

export const lastNameValidationRules = {
  minLength: {
    value: 2,
    message: 'Your last name must be at least 2 characters',
  },
  required: 'Your last name is required',
};

export const firstNameValidationRules = {
  minLength: {
    value: 2,
    message: 'Your first name must be at least 2 characters',
  },
  required: 'Your first name is required',
};

export const ageValidationRules = {
  min: {
    value: 18,
    message: 'You must be over 18 to join the programme',
  },
  max: {
    value: 120,
    message: 'This looks a little high. Are you sure?',
  },
  pattern: {
    value: /^[0-9]*$/,
    message: 'Please enter a number',
  },
  required: 'Please enter your age',
};

export const smartPhoneRegistration = {
  required: 'Please answer "Yes" or "No"',
  validate: (value: string): boolean => value === 'Yes' || value === 'No',
};

export const genderRegistration = {
  required: 'This is required',
  validate: (value: string): boolean => value === 'Male' || value === 'Female',
};

export const smokingStatusRegistration = {
  required: 'This is required',
  validate: (value: string): boolean =>
    value === 'Smoker' || value === 'Non Smoker' || value === 'Ex Smoker',
};

export const HEIGHT_ERRORS = [
  {
    condition: (heightCm: number): boolean => heightCm === 0,
    message: 'This field is required',
  },
  {
    condition: (heightCm: number): boolean => heightCm < 40,
    message: 'Are you sure this height is correct? It seems quite short!',
  },
  {
    condition: (heightCm: number): boolean => heightCm > 300,
    message: 'Are you sure this height is correct? It seems quite tall!',
  },
  {
    condition: (heightCm: number): boolean => Number.isNaN(heightCm),
    message: 'Please enter a whole number',
  },
];

export const WEIGHT_ERRORS = [
  {
    condition: (weightKg: number): boolean => weightKg === 0,
    message: 'This field is required',
  },
  {
    condition: (weightKg: number): boolean => weightKg < 30,
    message: 'Are you sure this weight is correct? It seems quite small!',
  },
  {
    condition: (weightKg: number): boolean => weightKg > 317,
    message:
      'Are you sure this weight is correct? Please double check the units are correct',
  },
  {
    condition: (weightKg: number): boolean => Number.isNaN(weightKg),
    message: 'Please enter a whole number',
  },
];

export const NDPP_CONTRACTS = [
  'NDPP Early Delivery',
  'NDPP Full Delivery',
  'NDPP 2022: Thrive Tribe',
  'NDPP 2022: Reed',
];

export const healthBarrierRegistration = {
  required: 'Please answer "Yes" or "No"',
  validate: (value: string): boolean | string =>
    value === 'Yes' ||
    `Please email us at support@secondnature.io to discuss your situation with one of our team before you enrol on the programme.`,
};

export const regularlyRecordRegistration = {
  required: 'Please answer "Yes" or "No"',
  validate: (value: string): boolean | string =>
    value === 'Yes' ||
    `Regular tracking of your health is a key aspect of the Second Nature programme - the technology allows you to set yourself goals and monitor your progress.`,
};

export const usePhoneAndAppRegistration = {
  required: 'Please answer "Yes" or "No"',
  validate: (value: string): boolean | string =>
    value === 'Yes' ||
    `To engage with the Second Nature programme you'll need to use the smartphone and web apps to chat to your mentors, track your progress, and read the Second Nature education content.`,
};

export const willingToCommunicateRegistration = (
  isSelfGuided: boolean,
): { required: string; validate: (value: string) => boolean | string } => ({
  required: 'Please answer "Yes" or "No"',
  validate: (value: string): boolean | string =>
    value === 'Yes'
      ? true
      : isSelfGuided
        ? 'This programme will be mostly self-guided, but you will have access technical support, FAQs and a community of other members and health coaches.'
        : 'Regular communication with your private health coach is a core part of the Second Nature programme and has been shown to be one of the most effective ways of helping improve your lifestyle.',
});

export const readyToBeSuccessfulRegistration = {
  required: 'Please answer "Yes" or "No"',
  validate: (value: string): boolean | string =>
    value === 'Yes' ||
    'Experience has taught us that the Second Nature programme is most effective when you also believe that you can make a change and be successful',
};

export const isOnHypoMedicationRegistration = {
  required: 'Please answer "Yes" or "No"',
  validate: (value: string): boolean | string =>
    value === 'Yes' || value === 'No',
};

export const hasConfirmedHypoRiskWithDoctorRegistration = {
  required: 'Please answer "Yes" or "No"',
  validate: (value: string): boolean | string =>
    value === 'Yes' ||
    'Please speak with your doctor before starting on the programme',
};

export const alcoholUnitsValidationRules = {
  required: 'This field is required',
  min: {
    value: 0,
    message: 'Value cannot be less than 0',
    max: {
      value: 1000,
      message: 'Value seems too high',
    },
    pattern: {
      value: /^[0-9]+$/,
      message: 'Please enter a valid number',
    },
  },
};

export const physicalActivityMinutesValidationRules = {
  required: 'This field is required',
  min: {
    value: 0,
    message: 'Value cannot be less than 0',
  },
  max: {
    value: 10080,
    message: 'Value seems too high',
  },
  pattern: {
    value: /^[0-9]+$/,
    message: 'Please enter a valid number',
  },
};

export const WMS_CONTRACTS = [
  'WMS level 1: Self guided',
  'WMS level 3: Coached',
];

export const NO_TECH_CONTRACTS = [
  'Type 2: Test Beds',
  'Type 2: SWL',
  'Weight management: Bath',
  'Weight management: Betsi Cadwaladr',
];

export const SELF_GUIDED_CONTRACTS = ['WMS level 1: Self guided'];

export const TYPE_2_CONTRACTS = [
  'Type 2: NWL',
  'Type 2: Portsmouth',
  'Type 2: Liverpool',
  'Type 2: SWL',
  'Type 2: Test Beds',
  'Type 2: East Berkshire',
  'Type 2: LAS and TfL',
  'Type 2: Doncaster',
  'Type 2: Scottish Highlands',
  'Type 2: Oxford',
  'Type 2: Dumfries and Galloway',
  'Type 2: Hastings',
  'Type 2: Tayside',
];

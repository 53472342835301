import { getBMI } from '../../../src/utils/numberUtils';

import { DiabetesStatusType, HealthConditionsType } from '@nextTypes/quiz';
import { calculateAge } from '../quizUtils';
import {
  EligibilityCriteria,
  EligibilityResult,
  checkForExcludedPregnanacyAnswers,
  reduceEligibilityCriteria,
} from '../../components/Quiz/QuizUtils';
import { QuizStateType } from '../../components/Quiz/state/state';

export const GLP1_EXCLUDED_HEALTH_CONDITIONS: HealthConditionsType[] = [
  'Thyroid cancer',
  'Cancer',
  'Inflammatory bowel disease',
  'Coeliac disease',
  'Eating disorder',
  'Chronic malabsorption syndrome',
  'Pancreatitis',
  'Impaired Liver',
  'Kidney disease',
  'Heart failure',
  'MEN-2',
  'Gallbladder problems',
  'Diabetic retinopathy',
];

// GLP1 criteria constants

export const GLP1_ELIGIBILITY_CRITERIA = {
  bmi: {
    min: 30,
  },
  bmiWithComorbidity: {
    min: 27,
    max: 30,
  },
  age: {
    min: 18,
    max: 75,
  },
  pregnancy: {
    excluded: ['pregnant', 'breastfeeding'],
  },
  healthConditions: {
    excluded: GLP1_EXCLUDED_HEALTH_CONDITIONS,
  },
  locale: {
    included: ['uk', undefined],
  },
};

export const meetsGLP1BMICriteria = (bmi: number | null): boolean =>
  bmi ? bmi >= GLP1_ELIGIBILITY_CRITERIA.bmi.min : false;

export const meetsGLP1AgeCriteria = (age: number): boolean =>
  age >= GLP1_ELIGIBILITY_CRITERIA.age.min &&
  age <= GLP1_ELIGIBILITY_CRITERIA.age.max;

const meetsGLP1DiabetesCriteria = (
  diabetesStatus: DiabetesStatusType | string,
): boolean => {
  if (diabetesStatus === 'type1' || diabetesStatus === 'type2') {
    return false;
  }
  return true;
};

/**
 * Returns the base criteria for GLP1 eligibility before injecting BMI criteria
 * for users either with or without a comorbidity
 *
 * @param {Object} answers - The user's answers to the quiz questions.
 * @param {number} answers.age - The user's age.
 * @param {number} answers.weightKg - The user's weight in kilograms.
 * @param {number} answers.heightCm - The user's height in centimeters.
 * @param {string} answers.gender - The user's gender.
 * @param {string} answers.pregnancy - The user's pregnancy status.
 * @param {Array} answers.healthConditions - The user's health conditions.
 * @param {string} answers.diabetesStatus - The user's diabetes status.
 * @param {string} locale - The active locale.
 * @returns {Array} An array of eligibility criteria, each with a key, value, and validity status.
 */
export const getGLP1BaseCriteria = (
  answers: Pick<
    QuizStateType,
    | 'dateOfBirth'
    | 'weightKg'
    | 'heightCm'
    | 'gender'
    | 'pregnancy'
    | 'healthConditions'
    | 'diabetesStatus'
  >,
  locale?: string,
): EligibilityCriteria[] => {
  const {
    dateOfBirth,
    weightKg,
    heightCm,
    pregnancy,
    healthConditions,
    diabetesStatus,
    gender,
  } = answers;

  const bmi = getBMI(weightKg || 0, heightCm || 0);
  const age = calculateAge(
    dateOfBirth
      ? new Date(dateOfBirth).toISOString()
      : new Date().toISOString(),
  );

  return [
    {
      key: 'age',
      value: age,
      isValid: !(age === undefined || !meetsGLP1AgeCriteria(age)),
    },
    {
      key: 'heightCm',
      value: heightCm,
      isValid: heightCm !== undefined,
    },
    {
      key: 'weightKg',
      value: weightKg,
      isValid: weightKg !== undefined,
    },
    {
      key: 'bmi',
      value: bmi,
      isValid: meetsGLP1BMICriteria(bmi),
    },
    { key: 'gender', value: gender, isValid: !!gender },
    {
      key: 'pregnancy',
      value: pregnancy,
      isValid: !(
        gender === 'female' &&
        checkForExcludedPregnanacyAnswers(
          GLP1_ELIGIBILITY_CRITERIA.pregnancy.excluded,
          pregnancy,
        )
      ),
    },
    {
      key: 'diabetesStatus',
      value: diabetesStatus,
      isValid: !(
        diabetesStatus === undefined ||
        !meetsGLP1DiabetesCriteria(diabetesStatus)
      ),
    },
    {
      key: 'healthConditions',
      value: healthConditions,
      isValid: !(
        healthConditions === undefined ||
        healthConditions.some(condition =>
          GLP1_ELIGIBILITY_CRITERIA.healthConditions.excluded.includes(
            condition,
          ),
        )
      ),
    },
    {
      key: 'locale',
      value: locale,
      isValid: GLP1_ELIGIBILITY_CRITERIA.locale.included.includes(locale),
    },
  ];
};

/**
 * Determines whether a user qualifies for the GLP1 programme, taking all their screening
 * answers into account.
 * @param {Pick<QuizStateType>} answers - The user's answers to the quiz questions.
 * @param {string} [locale] - The active locale.
 * @returns {EligibilityResult} Whether the user qualifies for GLP1 programme and any failing criteria.
 */
export const qualifiesForGLP1ProgrammeWithoutExtendedEligibility = (
  answers: Pick<
    QuizStateType,
    | 'weightKg'
    | 'heightCm'
    | 'gender'
    | 'pregnancy'
    | 'healthConditions'
    | 'diabetesStatus'
  >,
  locale?: string,
): EligibilityResult => {
  const { weightKg, heightCm } = answers;

  const bmi = getBMI(weightKg || 0, heightCm || 0);

  const criteria: EligibilityCriteria[] = [
    ...getGLP1BaseCriteria(answers, locale),
    {
      key: 'bmi',
      value: bmi,
      isValid: meetsGLP1BMICriteria(bmi),
    },
  ];

  return reduceEligibilityCriteria(criteria);
};

import {
  createContext,
  useReducer,
  useContext,
  useEffect,
  ReactNode,
  Dispatch,
} from 'react';

import {
  getLocalStorage,
  setLocalStorage,
} from '@components/NhsSignup/helpers/localStorage';
import {
  ActionDispatch,
  VitalitySignupActionType,
} from '@components/Vitality/state/actions';
import { reducer, ReducerState } from '@components/Vitality/state/reducer';
import {
  initialState,
  VitalityStateType,
} from '@components/Vitality/state/state';

const StateContext = createContext<VitalityStateType | undefined>(undefined);
const DispatchContext = createContext<ActionDispatch | undefined>(undefined);

function VitalitySignupProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [state, dispatch] = useReducer(
    reducer,
    getLocalStorage('cached-sign-up-vitality', initialState),
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { password, ...stateWithoutPassword } = state;

    setLocalStorage('cached-sign-up-vitality', stateWithoutPassword);
  }, [state]);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
}

const useVitalityContext = (): ReducerState => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('useVitalityContext must be used within a StateProvider');
  }
  return context;
};

const useVitalityDispatchContext = (): Dispatch<VitalitySignupActionType> => {
  const context = useContext(DispatchContext);
  if (context === undefined) {
    throw new Error(
      'useDispatchContext must be used within a DispatchProvider',
    );
  }
  return context;
};

export const useVitality = (): {
  state: VitalityStateType;
  dispatch: Dispatch<VitalitySignupActionType>;
} => {
  const state = useVitalityContext();
  const dispatch = useVitalityDispatchContext();

  return { state, dispatch };
};

export { VitalitySignupProvider as default };
